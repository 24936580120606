// SITE COLOURS
$color-darkbg: #1d1f35;

// SECTION COLOURS
$color-home: #45dca9;
$color-mass: #e9573f;
$color-sports: #00c9f7;
$color-fat: #ffd100;
$color-endurance: #c3c5c3;
$color-toning: #9b59b6;
$color-injury: #f59c00;
$color-wellbeing: #19a353;
