.sidebar-wrap {
    > * {
        height: 100%;
    }
}

@media screen and (min-width: 1201px) {
    .sidebar-wrap {
        flex: 0 1 20.9375rem;
        margin-top: 3.75rem;
    }
}

@media screen and (max-width: 1200px) {
    .sidebar-wrap {
        position: fixed;
        overflow-x: hidden;
        bottom: 0;
        left: 0; right: 0;
        z-index: 2;

        pointer-events: none;

        @media screen and (max-width: 320px) { top: 2.5rem; }
        @media screen and (min-width: 321px) and (max-width: 800px) { top: 3rem; }
        @media screen and (min-width: 801px) and (max-width: 1200px) { top: 3rem; }

        > * {
            overflow-y: scroll;

            pointer-events: auto;
        }
    }
}
