aside.sidebar {
    background: $color-darkbg;

    .B, .C {
        padding: 1.25rem;
    }

    h2 {
        text-align: center;
        background: darken($color-darkbg, 5%);
        color: #ffffff;
        border-bottom: 1px solid darken($color-darkbg, 15%);
        padding: 0.85rem 0.85rem 0.5rem 0.85rem;
    }

    .most-popular, .most-popular-vid {
        ul {
            list-style-type: none;

            li {
                padding: 0.7rem 1.5rem;

                &:first-of-type {margin-top: 0.625rem;}

                a {
                    color: #ffffff;
                    text-decoration: none;
                    font-size: 1rem;
                    font-weight: bold;
                    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
                }
            }
        }

        hr {
            border-top: 1px solid lighten($color-darkbg, 5%);
            border-bottom: 1px solid darken($color-darkbg, 10%);
            border-right:0; border-left: 0;

            &:last-of-type {border:0;}
        }

        svg {
            fill: #ffffff;
            width: 1rem;
            height: 1rem;
            margin-right: 0.8rem;
        }
    }
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    aside.sidebar {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
        width: 100%;

        &:not([data-visible]) {
            transform: translateX(100%);
            transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.6, 1);
        }

        &[data-visible] {
            transform: none;
            transition: transform 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    aside.sidebar {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
        width: 23.4375rem;

        &:not([data-visible]) {
            transform: translateX(100%);
            transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.6, 1);
        }

        &[data-visible] {
            transform: none;
            transition: transform 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
        }
    }
}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {
    aside.sidebar {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;
        width: 23.4375rem;

        &:not([data-visible]) {
            transform: translateX(100%);
            transition: transform 0.3s cubic-bezier(0.4, 0.0, 0.6, 1);
        }

        &[data-visible] {
            transform: none;
            transition: transform 0.3s cubic-bezier(0.0, 0.0, 0.2, 1);
        }
    }
}

//// LARGE SCREENS
//@media screen and (min-width: 1201px) and (max-width: 1600px) {
//
//}
//
//// X-LARGE SCREENS
//@media screen and (min-width: 1601px) {
//
//}
