.white-A-wrapper {
    display: flex;
    text-align: center;

    .A {
        margin: 0 auto;
    }
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
}

// LARGE SCREENS
@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
}

// X-LARGE SCREENS
@media screen and (min-width: 1601px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
}
