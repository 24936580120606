nav.top-nav, nav.mobile-nav * {
  -webkit-tap-highlight-color:transparent !important;
}

nav.top-nav, nav.mobile-nav {
    background: $color-darkbg;
    display: flex;
    justify-content: center;
    flex: 1 0 100%;

    div.center {
        display: flex;
        align-items: center;
    }

    a.logo {
        padding: 0;
        border-top: 0;
        align-items: center;
        display: flex;
        flex: 0 0 13rem;

        svg.heart {
            fill: #ffffff;
            height: 2.18rem;
        }

        svg.cuisique-text {
            fill: $color-home;
            height: 1.8rem;
            transform: translateY(1px);
            margin-left: 0.5rem;
            margin-right: 2rem;
        }
    }

    //NAVIGATION STYLES
    a {
        color: #ffffff;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: bold;
        padding: 1rem 1.25rem;
        display: inline-block;
        text-align: center;
        flex: 1 0 auto;

        &:first-of-type {
            text-align: left;
        }

        &.active, &:hover {
            transition: background 0.15s;
        }

        &.home {
            color: $color-home;
            border-top: 4px solid $color-home;

            &.active, &:hover {
                color: #ffffff;
                background: $color-home;
                border-top: 4px solid $color-home;
            }
        }
        &.mass {
            color: $color-mass;
            border-top: 4px solid $color-mass;

            &.active, &:hover {
                color: #ffffff;
                background: $color-mass;
                border-top: 4px solid $color-mass;
            }
        }
        &.sports {
            color: $color-sports;
            border-top: 4px solid $color-sports;

            &.active, &:hover {
                color: #ffffff;
                background: $color-sports;
                border-top: 4px solid $color-sports;
            }
        }
        &.fat {
            color: $color-fat;
            border-top: 4px solid $color-fat;

            &.active, &:hover {
                color: #ffffff;
                background: $color-fat;
                border-top: 4px solid $color-fat;
            }
        }
        &.endurance {
            color: $color-endurance;
            border-top: 4px solid $color-endurance;

            &.active, &:hover {
                color: #ffffff;
                background: $color-endurance;
                border-top: 4px solid $color-endurance;
            }
        }
        &.toning {
            color: $color-toning;
            border-top: 4px solid $color-toning;

            &.active, &:hover {
                color: #ffffff;
                background: $color-toning;
                border-top: 4px solid $color-toning;
            }
        }
        &.injury {
            color: $color-injury;
            border-top: 4px solid $color-injury;

            &.active, &:hover {
                color: #ffffff;
                background: $color-injury;
                border-top: 4px solid $color-injury;
            }
        }
        &.wellbeing {
            color: $color-wellbeing;
            border-top: 4px solid $color-wellbeing;

            &.active, &:hover {
                color: #ffffff;
                background: $color-wellbeing;
                border-top: 4px solid $color-wellbeing;
            }
        }
    }

    // MOBILE MENU STYLES
    .mobile-menu-button {
        display: flex;
        justify-content: flex-end;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.8rem;
        appearance:none;
        cursor: pointer;

        .menu-label {
            transform: translateY(2px);
        }

        a {
            padding: 5px;
        }

        & span {
            display: block;
            height: 3px;
            background: #ffffff;
            width: 1.5rem;
            border-radius: 0.25rem;
            margin-left: 0.5rem;
        }

        & span:nth-child(even) {
            background-color: $color-darkbg;
            height: 4px;
        }
    }
}


//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    nav.top-nav {
        display: block;

        div.center {
            padding: 0.5rem 1rem 0.5rem 1rem;
            justify-content: space-between!important;
            position: fixed;
            z-index: 10;
            width: 100%;
            background: $color-darkbg;
            margin: 0;
            top: 0;
            left: 0;
        }

        a.home, a.mass, a.sports, a.fat, a.endurance, a.toning, a.injury, a.wellbeing {
            display: none;
        }

        svg.heart {
            height: 1.8rem!important;
            margin: 0.1rem 0!important;
        }

        svg.cuisique-text {
            height: 1.4rem!important;

        }
    }

    nav.mobile-nav {
        justify-content: space-between;
        flex-flow: wrap;
        margin-top: 1rem;

        a {
            border-top: 0!important;
            font-size: 0.8rem!important;
            padding: 0.6rem;
            margin: 0.3rem 0;
         }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    nav.top-nav {
        div.center {
            padding: 0.5rem 1rem 0.5rem 1rem;
            justify-content: space-between!important;
            position: fixed;
            z-index: 10;
            width: 100%;
            background: $color-darkbg;
            margin: 0;
            top: 0;
            left: 0;
        }

        a.home, a.mass, a.sports, a.fat, a.endurance, a.toning, a.injury, a.wellbeing {
            display: none;
        }
    }

    nav.mobile-nav {
        justify-content: space-between;
        flex-flow: wrap;
        margin-top: 1rem;
        padding: 0 0.5rem;

        a {
            border-top: 0!important;
            font-size: 0.8rem!important;
            padding: 0.6rem;
            margin: 0.3rem 0;
         }
    }
}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {
    nav.top-nav {
        div.center {
            padding: 0.5rem 1rem 0.5rem 1rem;
            justify-content: space-between!important;
            position: fixed;
            z-index: 10;
            width: 100%;
            background: $color-darkbg;
            margin: 0;
            top: 0;
            left: 0;
        }

        a.home, a.mass, a.sports, a.fat, a.endurance, a.toning, a.injury, a.wellbeing {
            display: none;
        }
    }

    nav.mobile-nav {
        justify-content: space-between;
        flex-flow: wrap;
        margin-top: 1rem;
        padding: 0 0.5rem;

        a {
            border-top: 0!important;
            font-size: 0.8rem!important;
            padding: 0.6rem;
            margin: 0.3rem 0;
         }
    }
}

// LARGE SCREENS
@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .mobile-menu-button, nav.mobile-nav {display: none!important;}
}

// X-LARGE SCREENS
@media screen and (min-width: 1601px) {
    .mobile-menu-button, nav.mobile-nav {display: none!important;}

    .center {
        flex: 0 1 87.5rem;
    }
}
