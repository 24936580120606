.white-wrapper {
    background: #ffffff;
    display: flex;
    padding: 1.125rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

.third-width-article {
    height: 11.875rem;
    flex: 0 1 32%;
    margin-bottom: 1.25rem;
    text-decoration: none;
    position: relative;
    margin-bottom: 5.31rem;

    .tag {
        border-radius: 0 0 0.3rem 0;
        display: inline-block;
        padding: 0.1rem 0.2rem;
        transform: translateY(-1px);
    }

    .info {
        color: #ffffff;
        font-size: 0.8rem;
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 0.2rem 0.5rem;

        img.clock {
            height: 0.75rem;
            width: 0.75rem;
            transform: translateY(2px);
            margin-right: 0.3rem;
        }
    }

    h3 {
        color: #000000;
        font-size: 1rem;
        position: absolute;
        bottom: -4.68rem;
        width: 100%;
        height: 4rem;
    }
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    .third-width-article {
        height: 7rem!important;
        flex: 0 1 49%!important;

        .info {
            padding: 0.2rem 0.3rem;
            font-size: 0.6rem;

            svg {
                height: 0.65rem;
                width: 0.65rem;
                transform: translateY(1px);
                margin-right: 0.2rem;
            }
        }

        .tag {
            transform: translateY(-5px);
        }

        h3 {
            font-size: 0.8rem;
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    .third-width-article {
        height: 10rem!important;
        flex: 0 1 49%!important;

        .info {
            font-size: 0.6rem;
        }

        h3 {
            font-size: 0.8rem;
        }
    }

}

//// MEDIUM SCREENS
//@media screen and (min-width: 801px) and (max-width: 1200px) {
//
//}
//
//// LARGE SCREENS
//@media screen and (min-width: 1201px) and (max-width: 1600px) {
//
//}
//
//// X-LARGE SCREENS
//@media screen and (min-width: 1601px) {
//
//}
