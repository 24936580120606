.flex {
    display: flex;
}

.full-width-hero {
    height: 24.06rem;
    position: relative;
    width: 100%;
    padding: 20px;
    margin-top: -25px;

    h1 {
        position: absolute;
        bottom: 1.56rem;
        width: 95%;
        color: #ffffff;
        font-size: 2rem;
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
    }
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    .full-width-hero {
        margin-top: 1rem;

        h1 {
            font-size: 1.4rem;
            width: 90%;
        }

        &.large {
            display: none!important;
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    .full-width-hero {
        margin-top: 1rem;

        h1 {
            font-size: 1.5rem;
            width: 90%;
        }

        &.large {
            display: none!important;
        }
    }
}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {
    .full-width-hero {
        margin-top: 1rem;

        &.medium {
            display: none!important;
        }
    }
}

// LARGE SCREENS
@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .full-width-hero.medium {
        display: none!important;
    }
}

// X-LARGE SCREENS
@media screen and (min-width: 1601px) {
    .full-width-hero.medium {
        display: none!important;
    }
}
