footer {
    flex: 1 1 100%;
    display: flex;
    justify-content: center;

    nav {
        margin-top: 3rem;
        display: flex;
        justify-content: center;
        color: #585858;
        width: 100%;
        padding: 0 0.725rem;

        a {
            font-weight: bold;
            flex: 1 0 auto;
            text-decoration: none;
            color: #585858;
            padding: 1.25rem 0 0 0;
            font-size: 1rem;

            &[data-theme=home] {border-top: 4px solid $color-home;}
            &[data-theme=mass] {border-top: 4px solid $color-mass;}
            &[data-theme=sports] {border-top: 4px solid $color-sports;}
            &[data-theme=fat] {border-top: 4px solid $color-fat;}
            &[data-theme=endurance] {border-top: 4px solid $color-endurance;}
            &[data-theme=toning] {border-top: 4px solid $color-toning;}
            &[data-theme=injury] {border-top: 4px solid $color-injury;}
            &[data-theme=wellbeing] {border-top: 4px solid $color-wellbeing;}
        }
    }

    .copyright {
        display: flex;
        justify-content: space-between;
        margin: 3.125rem 0;
        text-align: right;
        line-height: 1rem;
        padding: 0 0.725rem;


        .social {
            display: flex;
            justify-content: space-between;
            position: relative;

            a {
                width: 50px;
                height: 50px;
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 70px;
                }

                .facebook {fill: #3b5998;}
                .twitter {fill: #4099ff;}
                .youtube {fill: #e52d27;}
                .googleplus {fill: #d34836;}

                *,
                *::before,
                *::after {
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }

                &.instagram {
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    display: block;
                    width: 50px;
                    height: 50px;
                    outline: none;
                    -webkit-border-radius: 11.11111px;
                    -moz-border-radius: 11.11111px;
                    border-radius: 11.11111px;
                    background: -webkit-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -webkit-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
                    background: -moz-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -moz-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
                    background: -ms-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -ms-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
                    background: -o-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -o-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
                    background: radial-gradient(circle farthest-corner at 32% 106%, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
                }

                &.instagram figure#logo {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    display: block;
                    width: 36.805px;
                    height: 36.805px;
                    border: 3.402px solid rgb(255, 255, 255);
                    -webkit-border-radius: 10.4166px;
                    -moz-border-radius: 10.4166px;
                    border-radius: 10.4166px;
                }

                &.instagram figure#logo::before {
                    content: "";
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                    display: block;
                    width: 19.027px;
                    height: 19.027px;
                    border: 3.402px solid rgb(255, 255, 255);
                    -webkit-border-radius: 9.513px;
                    -moz-border-radius: 9.513px;
                    border-radius: 9.513px;
                }

                &.instagram figure#logo::after {
                    content: "";
                    top: 2.986px;
                    right: 2.986px;
                    position: absolute;
                    display: block;
                    width: 4.444px;
                    height: 4.444px;
                    background: rgb(255, 255, 255);
                    -webkit-border-radius: 2.2222px;
                    -moz-border-radius: 2.2222px;
                    border-radius: 2.2222px;
                }
            }
        }

        svg.cuisique-text {
            fill: $color-darkbg;
            width: 10.3125rem;
            height: 2.8125rem;
            margin-left: 8px;
            flex: 0 0 auto;
        }

        span {
            flex: 0 0 auto;
            font-size: 0.7rem;
            padding-top: 0.9375rem;

            a {
                color: #585858;
            }
        }

    }
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    footer {
        nav {
            flex-flow: wrap;

            a {
                flex: 1 1 40%!important;
                padding: 0.5rem 0 1.25rem 0!important;
                margin: 0 0.3rem;
            }
        }

        .copyright {
            flex-direction: column;
            text-align: center;
            line-height: 1.1rem;
            padding: 0 3rem;

            .social a {
                margin-right: 0.3125rem;
                width: 2rem;
                height: 2rem;

                &:last-of-type {
                    margin-right: 3.75rem;
                }
            }

            svg.cuisique-text {
                margin: 0 auto 1rem auto!important;
            }
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    footer {
        nav {
            flex-flow: wrap;

            a {
                flex: 1 1 40%!important;
                padding: 0.5rem 0 1.25rem 0!important;
                margin: 0 0.3rem;
            }
        }

        .copyright {
            flex-direction: column;
            text-align: center;
            line-height: 1.1rem;
            padding: 0 3rem;

            .social a {
                margin-right: 0.3125rem;
                width: 2rem;
                height: 2rem;

                &:last-of-type {
                    margin-right: 5rem;
                }
            }

            svg.cuisique-text {
                margin: 0 auto 1.5rem auto!important;
            }
        }
    }

}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {

}

// LARGE SCREENS
@media screen and (min-width: 1201px) and (max-width: 1600px) {

}

// X-LARGE SCREENS
@media screen and (min-width: 1601px) {

}
