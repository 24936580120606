article {
    padding: 1.25rem;
    background: #ffffff;

    header {
        margin-bottom: 2.8125rem;
        display: flex;
        justify-content: space-between;

        svg.clock, img.clock {
            width: 1.125rem;
            height: 1.125rem;
            transform: translateY(-1px);
            margin-right: 0.625rem;
        }

        .author {
            margin-right: 1rem;
            font-size: 0.8rem;
        }
    }

    h2 {
        margin-bottom: 1.5rem;
        font-size: 1.3rem;
    }

    p {
        font-size: 1rem;
        margin-bottom: 1.4rem;
        text-align: justify;
        line-height: 1.4rem;

        &:first-of-type {
            font-weight: bold;
        }

        &:last-of-type {
            margin-bottom: 5rem;
        }
    }

	a {
		text-decoration: none;
		color: #000000;

		&:hover {
		 	transition: color 0.3s;
		}
	}

	a.button {
		border-radius: 0.2rem;
		text-decoration: none;
		padding: 0.5rem 1rem;

		&:hover {
			opacity: 0.7;
			transition: opacity 0.3s;
		}
	}

    sup {
        font-weight: bold;
    }

    ul, ol {
        padding: 0 1rem 1rem 1rem;
        line-height: 1.4;

        li {
            padding: 0 0 0.55rem 0;
        }
    }

    figure {
        font-weight: bold;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        width: 50%;

        img {
            margin-bottom: 1rem;
            max-width: 100%;
        }
    }

    figure.right {
        float: right;
        margin-left: 1.25rem;
        margin-bottom: 1.25rem;
    }

    figure.left {
        float: left;
        margin-right: 1.25rem;
        margin-bottom: 1.25rem;
    }

    footer {
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        h2 {
            padding: 1rem 1rem 0.8rem 1rem;
            margin-bottom: 0;
        }

        p, ol, ul {
            padding: 1rem 1rem 0 1rem;
            margin-bottom: 0;

            &:first-of-type {
                padding-top: 2rem;
                font-weight: normal;
            }

            &:last-of-type {
                padding-bottom: 2rem;
                margin-bottom: 0;
            }
        }

        ol, ul {
            li {
                margin-left: 1rem;
                padding-bottom: 0.3rem;

                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }

        a {color: #000000;}
    }
}

// CHANGES THE COLOUR OF ALL FOOTNOTE SECTIONS
main[data-theme=mass] article footer p, main[data-theme=mass] article footer ol, main[data-theme=mass] article footer ul {background: lighten($color-mass, 35%);}
main[data-theme=sports] article footer p, main[data-theme=sports] article footer ol, main[data-theme=sports] article footer ul {background: lighten($color-sports, 35%);}
main[data-theme=fat] article footer p, main[data-theme=fat] article footer ol, main[data-theme=fat] article footer ul {background: lighten($color-fat, 35%);}
main[data-theme=endurance] article footer p, main[data-theme=endurance] article footer ol, main[data-theme=endurance] article footer ul {background: mix($color-endurance, white, 25%);}
main[data-theme=toning] article footer p, main[data-theme=toning] article footer ol, main[data-theme=toning] article footer ul {background: lighten($color-toning, 35%);}
main[data-theme=injury] article footer p, main[data-theme=injury] article footer ol, main[data-theme=injury] article footer ul {background: lighten($color-injury, 35%);}
main[data-theme=wellbeing] article footer p, main[data-theme=wellbeing] article footer ol, main[data-theme=wellbeing] article footer ul {background: lighten($color-wellbeing, 35%);}

// CHANGES THE COLOUR OF ALL FOOTNOTES H2s
main[data-theme=mass] article footer h2 {background: lighten($color-mass, 25%);}
main[data-theme=sports] article footer h2 {background: lighten($color-sports, 25%);}
main[data-theme=fat] article footer h2 {background: lighten($color-fat, 25%);}
main[data-theme=endurance] article footer h2 {background: mix($color-endurance, white, 35%);}
main[data-theme=toning] article footer h2 {background: lighten($color-toning, 25%);}
main[data-theme=injury] article footer h2 {background: lighten($color-injury, 25%);}
main[data-theme=wellbeing] article footer h2 {background: lighten($color-wellbeing, 25%);}

// CHANGES THE COLOUR OF ALL ARTICLE LINKS
main[data-theme=mass] article a {border-bottom: 2px solid $color-mass; &:hover{color: $color-mass;}}
main[data-theme=sports] article a {border-bottom: 2px solid $color-sports; &:hover{color: $color-sports;}}
main[data-theme=fat] article a {border-bottom: 2px solid $color-fat; &:hover{color: $color-fat;}}
main[data-theme=endurance] article a {border-bottom: 2px solid $color-endurance; &:hover{color: $color-endurance;}}
main[data-theme=toning] article a {border-bottom: 2px solid $color-toning; &:hover{color: $color-toning;}}
main[data-theme=injury] article a {border-bottom: 2px solid $color-injury; &:hover{color: $color-injury;}}
main[data-theme=wellbeing] article a {border-bottom: 2px solid $color-wellbeing; &:hover{color: $color-wellbeing;}}
main[data-theme=mass] article a.button {background-color: $color-mass; color: #ffffff}
main[data-theme=sports] article a.button {background-color: $color-sports; color: #ffffff}
main[data-theme=fat] article a.button {background-color: $color-fat; color: #ffffff}
main[data-theme=endurance] article a.button {background-color: $color-endurance; color: #ffffff}
main[data-theme=toning] article a.button {background-color: $color-toning; color: #ffffff}
main[data-theme=injury] article a.button {background-color: $color-injury; color: #ffffff}
main[data-theme=wellbeing] article a.button {background-color: $color-wellbeing; color: #ffffff}

// CHANGES THE COLOUR OF THE CLOCK ICON
main[data-theme=mass] article header svg.clock {fill: $color-mass;}
main[data-theme=sports] article header svg.clock {fill: $color-sports;}
main[data-theme=fat] article header svg.clock {fill: $color-fat;}
main[data-theme=endurance] article header svg.clock {fill: $color-endurance;}
main[data-theme=toning] article header svg.clock {fill: $color-toning;}
main[data-theme=injury] article header svg.clock {fill: $color-injury;}
main[data-theme=wellbeing] article header svg.clock {fill: $color-wellbeing;}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    article {
        header {
            flex-wrap: wrap;
            justify-content: flex-start!important;

            .author {
                font-size: 0.5rem;
                margin: 0!important;
            }

            svg.clock {
                width: 0.8rem;
                height: 0.8rem;
            }
        }

        figure {
            img {
                width: 100%;
            }

            &.left {
                margin-right:0;
            }

            &.right {
                margin-left:0;
            }
        }

        footer {
            h2 {
                font-size: 1rem!important;
            }
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    article {
        header {
            flex-wrap: wrap;
            justify-content: flex-start!important;

            .author {
                font-size: 0.8rem;
            }
        }

        figure {
            img {
                width: 100%;
            }
        }

        footer {
            h2 {
                font-size: 1rem!important;
            }
        }
    }
}

//// MEDIUM SCREENS
//@media screen and (min-width: 801px) and (max-width: 1200px) {
//
//}
//
//// LARGE SCREENS
//@media screen and (min-width: 1201px) and (max-width: 1600px) {
//
//}
//
//// X-LARGE SCREENS
//@media screen and (min-width: 1601px) {
//
//}
