div.s-s {
    display: inline-flex;
    justify-content: space-between;
    flex: 1 1 auto;

    .outer-label {
        font-size: 0.8rem!important;
    }

    a.share {
        text-decoration: none;
        color: #ffffff;
        border-radius: 3px;
        padding: 0.2rem 0.3rem 0.1rem 0.3rem;
        font-size: 0.8rem;
        flex: 0 0 auto;
        transform: translateY(-6px);
		border: 0!important;

		&:hover {
			color: #ffffff;
		}

        svg {
            fill: #ffffff;
            height: 15px;
            width: 15px;
            transform: translateY(1px);
            margin-right: 0.3rem;
        }

        &.e {
            background: #e4e6e8;
            color: #000000;
        }

        &.t {
            background: #4099ff;
        }

        &.f {
            background: #3b5998;
        }

        &.g {
            background: #d34836;
        }

        &.w {
            background: #25D366;
        }

        &.r {
            background: #cee3f8;
            color: #000000;
        }

        &.linkedin {
            background: #0077B5;
        }
    }
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    .s-s {
        margin-top: 1.55rem;
        flex-flow: wrap;
        flex: 0 0 100%;

        a.share {
            flex: 0 0 10%!important;
            margin-bottom: 0.6rem;
            padding: 0.2rem 0.2rem 0 0.2rem!important;

            .label {
                display:none;
            }
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    .s-s {
        margin-top: 1.55rem;
        flex-flow: wrap;
        flex: 0 0 100%;

        a.share {
            flex: 0 0 10%!important;
            margin-bottom: 0.6rem;
            padding: 0.2rem 0.2rem 0 0.2rem!important;
            text-align: center;
            height: auto;

            .label {
                display:none;
            }
        }

        svg {
            height: 12px!important;
            width: 12px!important;
        }
    }
}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {
    .s-s {
        a.share {
            height: 1.55rem;
        }
        .outer-label {
            display:none;
        }
    }
}

// LARGE SCREENS
@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .s-s {
        a.share {
            height: 1.55rem;
        }
        .outer-label {
            display:none;
        }
    }
}

// X-LARGE SCREENS
@media screen and (min-width: 1601px) {
    .s-s {
        a.share {
            height: 1.55rem;
        }
        .outer-label {
            display:none;
        }
    }
}
