.half-width-featured {
    height: 18.125rem;
    position: relative;
    flex: 0 1 49%;

    .pad {
        padding: 10px;
    }

    h2 {
        position: absolute;
        bottom: 1rem;
        height: 2.51rem;
        width: 95%;
        color: #ffffff;
        font-size: 1.125rem;
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
    }
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    .half-width-featured {
        flex: 0 1 100%;
        height: 15.125rem;

        &:first-of-type {
            margin-bottom: 1rem;
        }

        h2 {
            width: 93%;
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    .half-width-featured {
        flex: 0 1 100%;
        height: 18.125rem;

        &:first-of-type {
            margin-bottom: 1rem;
        }
    }
}

//// MEDIUM SCREENS
//@media screen and (min-width: 801px) and (max-width: 1200px) {
//
//}
//
//// LARGE SCREENS
//@media screen and (min-width: 1201px) and (max-width: 1600px) {
//
//}
//
//// X-LARGE SCREENS
//@media screen and (min-width: 1601px) {
//
//}
