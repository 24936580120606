.apple-watch-giveaway {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
	margin-top: 2rem;

	.text {
		background: $color-darkbg;
		position: relative;
		padding: 1rem 2rem 1rem 1rem;
		z-index: 2;

		&:after {
			background: inherit;
			content: "";
			display: block;
			height: 100%;
			width: 75px;
			position: absolute;
			right: -60px;
			top: 0;
			-webkit-backface-visibility: hidden;
			transform: skewX(-20deg);
    		transform-origin: 100% 0;
			z-index: 0;
		}

		svg {
			height: 2rem;

			&.heart {
				transform: translateY(-2px);
			}

			&.cuisique-text {
				fill: $color-home;
			}

			& + svg {
				margin-left: 0.5rem;
			}
		}

		h2 {
			color: #ffffff;
			text-transform: uppercase;
			font-size: 2.2rem;
			margin-bottom: 0;
		}

		.atc {
			display: flex;
			justify-content: space-between;
			align-items: center;

			* {
				z-index: 2;
			}

			p {
				color: $color-home;
				text-transform: uppercase;
				margin-bottom: 0;
				margin-right: 1rem;
				text-align: left;
			}
		}
	}

	> a {
		border: none!important;
		margin: 0 auto;
		align-self: center;
	}
}

// CHANGES THE COLOUR OF ALL CUISIQUE HEART LOGOS
main[data-theme=mass] .apple-watch-giveaway .heart {fill: $color-mass}
main[data-theme=sports] .apple-watch-giveaway .heart {fill: $color-sports}
main[data-theme=fat] .apple-watch-giveaway .heart {fill: $color-fat}
main[data-theme=endurance] .apple-watch-giveaway .heart {fill: $color-endurance}
main[data-theme=toning] .apple-watch-giveaway .heart {fill: $color-toning}
main[data-theme=injury] .apple-watch-giveaway .heart {fill: $color-injury}
main[data-theme=wellbeing] .apple-watch-giveaway .heart {fill: $color-wellbeing}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
	.apple-watch-giveaway {
		.text {
			padding: 0.5rem!important;

			&:after {
				width: 30px;
				right: -25px;
				transform: skewX(-8deg);
			}

			svg { height: 1rem; }
			h2 { font-size: 1rem!important; }
			p { font-size: 0.8rem!important; line-height: 1rem!important; }

			.atc {
				flex-direction: column;

				.button {
					margin-top: 0.5rem!important;
					font-size: 0.8rem;
					padding: 0.5rem;
				}
			}
		}

		img {
			width: 35%!important;
			height: 35%!important;
		}
	}
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
	.apple-watch-giveaway {
		.text {
			padding-right: 1rem!important;

			&:after {
				width: 30px;
				right: -30px;
				transform: skewX(-5deg);
			}

			svg { height: 1.5rem; }

			h2 { font-size: 1.3rem!important; }
			p { font-size: 0.8rem!important; line-height: 1rem; }

			.atc {
				flex-direction: column;

				.button { margin-top: 0.5rem!important;	}
			}
		}
	}
}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {
}

// LARGE SCREENS
@media screen and (min-width: 1201px) and (max-width: 1600px) {

}

// X-LARGE SCREENS
@media screen and (min-width: 1601px) {

}
