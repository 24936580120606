@media print {
    * {-webkit-print-color-adjust:exact;}

    nav.top-nav {
        display: none!important;
    }

    .article-sidebar, .sidebar-wrap {
        display: none!important;
    }

    .full-width-hero {
        height: 10rem!important;

        h1 {
            font-size: 1.5rem!important;
        }
    }

    .main-col::before {
        width: 0.75rem!important;
    }

    article {
        header {
            justify-content: flex-start!important;
            margin-bottom: 1.5rem!important;
        }

        figure {
            font-size: 0.6rem!important;
            width: 33%!important;
        }

        h2 {
            font-size: 0.9rem!important;
            margin-bottom: 0.6rem!important;
        }

        p {
            font-size: 0.7rem!important;
            line-height: 1rem!important;
            margin-bottom: 0.6rem!important;

            &:last-of-type {
                margin-bottom: 0!important;
            }
        }

        ul li, ol li {
            font-size: 0.7rem!important;
        }

        #notes {
            display: none!important;
        }
    }

    .social-shares, .social {
        display: none!important;
    }

    .fb-comments {
        display: none!important;
    }

    footer {
        nav {
            display: none!important;
        }

        .copyright {
            margin-top: 2rem!important;

            svg {
                width: 6rem!important;
            }
        }
    }

    @page {
        margin: 1cm 1.5cm;
    }
}
