.flex {
    display: flex;
}

.full-width-video {
    position: relative;
    width: 100%;
    margin-top: -25px;
    background: #ffffff;

    iframe {
        width: 100%;
        height: 24.06rem;
        flex: 1 1 100%;
    }

    h1 {
        width: 95%;
        color: #000000;
        font-size: 2rem;
        padding: 1rem;
        flex: 1 1 95%;
    }

    .tags {
        padding: 0 1rem;

        a.tag {
            margin-bottom: 5px;
        }
    }
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    .full-width-video {
        iframe {
            height: 10rem;
        }

        h1 {
            font-size: 1.3rem;
            width: 90%;
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    .full-width-video {
        iframe {
            height: 14rem;
        }

        h1 {
            font-size: 1.5rem;
            width: 90%;
        }
    }
}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {

}

// LARGE SCREENS
@media screen and (min-width: 1201px) and (max-width: 1600px) {

}

// X-LARGE SCREENS
@media screen and (min-width: 1601px) {

}
