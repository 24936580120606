main {
    position: relative;
    flex: 0 1 53.125rem;
    padding: 50px 0 0 0;
    margin-left: 1.75rem;
}

main:before {
    content: "";
    position: absolute;
    top: 3.75rem;
    bottom: 0;
    width: 1.75rem;
    pointer-events: none;
    user-select: none;
    transform: translate(-100%, 0);
}

main[data-theme=home]:before {
   background: $color-home;
}

main[data-theme=mass]:before {
   background: $color-mass;
}

main[data-theme=sports]:before {
   background: $color-sports;
}

main[data-theme=fat]:before {
   background: $color-fat;
}

main[data-theme=endurance]:before {
   background: $color-endurance;
}

main[data-theme=toning]:before {
   background: $color-toning;
}

main[data-theme=injury]:before {
   background: $color-injury;
}

main[data-theme=wellbeing]:before {
   background: $color-wellbeing;
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    main {
        margin-left: 0.65rem!important;

        &:before {
            width: 0.65rem!important;
            margin-top: 1.5rem;
        }
    }
}

// SMALL SCREENS
@media screen and (min-width: 321px) and (max-width: 800px) {
    main {
        margin-left: 0.75rem!important;

        &:before {
            width: 0.75rem!important;
            margin-top: 1.5rem;
        }
    }
}

// MEDIUM SCREENS
@media screen and (min-width: 801px) and (max-width: 1200px) {
    main {
        &:before {
            margin-top: 2rem;
        }
    }
}

//// LARGE SCREENS
//@media screen and (min-width: 1201px) and (max-width: 1600px) {
//
//}

// X-LARGE SCREENS
@media screen and (min-width: 1601px) {
    main {
        flex: 0 1 65rem;
    }
}
