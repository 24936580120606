.tag {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem;
    padding: 0.3rem 0.4rem 0.2rem 0.4rem;
    border-radius: 0.3rem;
    text-decoration: none;
    white-space: nowrap;

    &:not(:last-child) { margin-right: 0.6rem; }
}

.tag.home {
   background: $color-home;
}

.tag.mass {
   background: $color-mass;
}

.tag.sports {
   background: $color-sports;
}

.tag.fat {
   background: $color-fat;
}

.tag.endurance {
   background: $color-endurance;
}

.tag.toning {
   background: $color-toning;
}

.tag.injury {
   background: $color-injury;
}

.tag.wellbeing {
   background: $color-wellbeing;
}

//---------------------------------------------------
//--------------- RESPONSIVE STYLES -----------------
//---------------------------------------------------

// MOBILE SCREENS
@media screen and (max-width: 320px) {
    .tag {
        font-size: 0.5rem;
    }
}

//// SMALL SCREENS
//@media screen and (min-width: 321px) and (max-width: 800px) {
//
//}
//
//// MEDIUM SCREENS
//@media screen and (min-width: 801px) and (max-width: 1200px) {
//
//}
//
//// LARGE SCREENS
//@media screen and (min-width: 1201px) and (max-width: 1600px) {
//
//}
//
//// X-LARGE SCREENS
//@media screen and (min-width: 1601px) {
//
//}
